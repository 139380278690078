import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { timeInterval } from 'rxjs/operators';
import { PasswordClass } from 'src/app/digilegal/search/case.model';
import { CaseService } from 'src/app/digilegal/search/case.service';

@Component({
  selector: 'app-main-layout',
  templateUrl: './main-layout.component.html',
  styleUrls: [],
})
export class MainLayoutComponent implements OnInit {
  passwordClass=new PasswordClass();
 
  constructor(private router: Router
    ,private _caseService: CaseService) {

      

    }
  ngOnInit() { 
    console.log("MainLayoutComponent");
    this._caseService.isGenerateKey().subscribe((res: any) => { 
      //console.log("SearchPage "+(JSON.stringify(res)));
      //this.caseObject=res; 
      var userObjectDetails = JSON.parse(localStorage.getItem("userObjectDetails"));
      console.log("userObjectDetails==");
      //console.log(userObjectDetails);
      console.log(res);
      this.passwordClass = res; 
     if(this.passwordClass.status=='FileNotFound'){
        console.log("FileNotFound");
        this.router.navigate(['/signup']);
      }
      else if(this.passwordClass.status=='VeriFiedUser'){
        if(userObjectDetails)
        console.log("VeriFiedUser "+this.passwordClass.regKey+" "+this.passwordClass.regKey+" "+this.passwordClass.licenceName);
        localStorage.setItem("username",this.passwordClass.username);
        localStorage.setItem("email",this.passwordClass.email);
        localStorage.setItem("licenceName",this.passwordClass.licenceName);
        
        var expirationMin=60;
        var expirationMS = expirationMin * 60 * 1000;
        var record = {value: JSON.stringify(this.passwordClass), timestamp: new Date().getTime() + expirationMS}
        localStorage.setItem("userObjectDetails", JSON.stringify(record));
    
        this.router.navigate(['/digilegal-search']);        
      }
      else{
        console.log("VeriFiedUserElse");
        this.router.navigate(['/signup']);
      }

    }) ;

   
  }
}

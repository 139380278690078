export class HighlightPage {
    content:           Content[];
    facetResultPages:  any[];
    facetQueryResult:  FacetQueryResult;
    highlighted:       Highlighted[];
    maxScore:          null;
    fieldStatsResults: FieldStatsResults;
    facetPivotFields:  any[];
    allFacets:         null[];
    facetFields:       any[];
    totalPages:        number;
    totalElements:     number;
    size:              number;
    number:            number;
    sort:              null;
    first:             boolean;
    last:              boolean;
    numberOfElements:  number;
    free_word:string;
}

export class Content {
    id:                number;
    judge_name:        string;
    pub_name:          string;
    case_id:           number;
    judgement:         string;
    respondent:        string;
    headnote:          string;
    appealant:         string;
    appealant_ngram:   string;
    judgement_date:    number;
    appeal:            null;
    citations:         string;
    casereferred:      null;
    created_date_time: number;
    act_name:          string;
    h_id:              number;
    is_case_extra:     IsCaseExtra;
    judge_id:          null;
    judge_names:       null;
    h_name:            HName;
}

export enum HName {
    SupremeCourt = "Supreme Court",
}

export enum IsCaseExtra {
    CaseNoteNo = "CaseNoteNo",
}

export class FacetQueryResult {
    content:          any[];
    totalPages:       number;
    last:             boolean;
    totalElements:    number;
    size:             number;
    number:           number;
    sort:             null;
    first:            boolean;
    numberOfElements: number;
}

export class FieldStatsResults {
}

export class Highlighted {
    entity:     Content;
    highlights: Highlight[];
}

export class Highlight {
    field:     Field;
    snipplets: string[];
}

export class Field {
    name: Name;
}

export enum Name {
    Headnote = "HEADNOTE",
    Judgement = "JUDGEMENT",
}


export class DigilegalUtil{

    APPLICATION_ICON ="images/digi.png";
    SPLASH_IMAGE ="file:images/digilegalimage.jpg";
    ICON_IMAGE ="images/unnamed.png";
    MESSAGE1=" Register!!Supreme Court Search";
    PRODUCT_NAME=" Register!!Supreme Court Search";
    PRODUCT_VERSION=" V1.0";
    PRODUCT_RELEASE_DATE=" June 2020";
    DATABASE_NAME="Supreme Court";
    //FINAL_PRODUCT_DESC:string=PRODUCT_NAME+PRODUCT_VERSION+PRODUCT_RELEASE_DATE;   
   FinalqueryExactWord:string;
   FinalqueryAllWord:string;
   FinalqueryAnyWord:string;
   FinalqueryNearWord:string;
   
   

}


export class BookMark{

    bookMarkName:string;
    bookmarkIds:string;

}

export class PasswordClass {
	
	public  username:string;
	public  email:string;
	public  city:string;
	public  regCode:string;
    public  regKey:string;
    public serialNumber:string;
    public status;string;
    public lastLogin:number;
    public phone:string;
    public licenceName:string;
}
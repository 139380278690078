import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CaseService } from './case.service';
import { PasswordClass } from './case.model';
@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.scss'],
})
export class SignupComponent implements OnInit {
  validPattern = "^[a-zA-Z0-9]{5,15}$"; // alphanumeric exact 10 letters
  loginForm: FormGroup;
  submitted = false;
  submittedErrorMsg:string;
  returnUrl: string;
  hide = true;
  chide = true;
  regCode:any;
  passwordClass=new PasswordClass();
  tokenGenerated:string;
  constructor(private formBuilder: FormBuilder, private router: Router
    ,private _caseService: CaseService) {      
    }
  ngOnInit() {    
   

    this.loginForm = this.formBuilder.group({
      username: ['', [Validators.required,Validators.pattern(this.validPattern)]],
      email: [
        '',
        [Validators.required, Validators.email, Validators.minLength(5)],
      ],
      city: ['', Validators.required],
      regCode: [this.regCode, Validators.required],
      regKey: ['', Validators.required],
      serialNumber: ['', Validators.required],
      phone: ['', Validators.required],
      licenceName: ['', Validators.required],
      
    });   
    this.usernameChange();

  }

  usernameChange(){
    var d = new Date();
    var dayValue=d.getDate();
    var monthValue=d.getMonth()+1;
    var minValue=d.getMinutes();
    var secValue=d.getSeconds();
    // #BCI Chnages Start#
    this.tokenGenerated="BCI"+secValue+""+minValue+""+dayValue+"";
    // #BCI Chnages END#
    this.loginForm.get("username").setValue(this.tokenGenerated);
    this.regCode=this.tokenGenerated;
    this._caseService.generateKey(this.regCode).subscribe((res: any) => { 
      //console.log("SearchPage "+(JSON.stringify(res)));
      //this.caseObject=res; 
      //console.log(res);
      this.passwordClass = res; 
      this.loginForm.get("regCode").setValue( this.passwordClass.regCode);
      this.loginForm.get("serialNumber").setValue( this.passwordClass.serialNumber);
    }) ;
  }

  regKeyChange(){

    var regKey=this.loginForm.get("regKey").value;
    var username=this.loginForm.get("username").value;
    var serialNumber=this.loginForm.get("serialNumber").value;
    username=this.tokenGenerated;
    this._caseService.generateKeyForVerification(username,serialNumber).subscribe((res: any) => { 
      //console.log("SearchPage "+(JSON.stringify(res)));
      this.passwordClass = res;      
      if(regKey==this.passwordClass.regKey)
      {
          this.submitted=true;
          this.submittedErrorMsg="ValidRegKey";
          sessionStorage.setItem("status","ValidRegKey");
      }
      else
      {
        this.submitted=false;
        this.submittedErrorMsg="InvalidRegKey";
        sessionStorage.setItem("status","InvalidRegKey");
      }
    }) ;
  }

  get f() {
    return this.loginForm.controls;
  }
  onSubmit() {
    this.submitted = true;
    // stop here if form is invalid
    if (this.loginForm.invalid) {
      return;
    } 
    else if(this.submittedErrorMsg=="ValidRegKey")
    {
      let ob = new PasswordClass();
      Object.assign(ob, this.loginForm.value);
      console.log(this.loginForm.value);
      console.log(ob);

      this._caseService.saveRegistrationDetails(ob).subscribe((res: any) => {
        //console.log("Few "+res); 
        var expirationMin=60;
        var expirationMS = expirationMin * 60 * 1000;
        var record = {value: JSON.stringify(res), timestamp: new Date().getTime() + expirationMS}
        localStorage.setItem("userObjectDetails", JSON.stringify(record));     
        console.log(JSON.stringify(record));          
        this.router.navigate(['/']);          
      });
     
    }
   
  }

  // Only AlphaNumeric
  keyPressAlphaNumeric(event) {

    var inp = String.fromCharCode(event.keyCode);

    if (/[a-z0-9]/.test(inp)) {
      return true;
    } else {
      event.preventDefault();
      return false;
    }
  }
}

import { RouteInfo } from './sidebar.metadata';
export const ROUTES: RouteInfo[] = [
  {
    path: '/digilegal-search',
    title: '-- Main',
    moduleName: '',
    iconType: '',
    icon: '',
    class: '',
    groupTitle: true,
    badge: '',
    badgeClass: '',
    submenu: [],
  },
  {
    path: '/digilegal-search',
    title: 'Search',
    moduleName: 'dashboard',
    iconType: 'material-icons-two-tone',
    icon: 'image_search',
    class: '',
    groupTitle: false,
    badge: '',
    badgeClass: '',
    submenu: [],
  }
  ,
  {
    path: '/digilegal-search/history',
    title: 'History',
    moduleName: 'dashboard',
    iconType: 'material-icons-two-tone',
    icon: 'history',
    class: '',
    groupTitle: false,
    badge: '',
    badgeClass: '',
    submenu: [],
  }
  ,
  {
    path: '/digilegal-search/bookMark',
    title: 'Bookmark',
    moduleName: 'bookmark',
    iconType: 'material-icons-two-tone',
    icon: 'bookmark',
    class: '',
    groupTitle: false,
    badge: '',
    badgeClass: '',
    submenu: [],
  }
  ,
  {
    path: '/digilegal-search/overruled',
    title: 'Overruled',
    moduleName: 'overrule',
    iconType: 'material-icons-two-tone',
    icon: 'layers',
    class: '',
    groupTitle: false,
    badge: '',
    badgeClass: '',
    submenu: [],
  },
  {
    path: '/digilegal-search/bcrBooks',
    title: 'BCR Books',
    moduleName: 'bcrBooks',
    iconType: 'material-icons-two-tone',
    icon: 'layers',
    class: '',
    groupTitle: false,
    badge: '',
    badgeClass: '',
    submenu: [],
  }

    
];

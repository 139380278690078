
<app-header></app-header>
<app-sidebar></app-sidebar>
<app-right-sidebar></app-right-sidebar>
<router-outlet></router-outlet>
<div class="footer">
    <div class="row">
        <div class="col-xl-4 col-lg-4 col-md-4 col-sm-4 mb-2 text-truncate text-left">
             Contact:– 9321947751/ 02266343514
        </div>
        <div class="col-xl-4 col-lg-4 col-md-4 col-sm-4 mb-2 text-truncate text-center">
            Website:-<a target="_blank" href="https://digilegal.co.in/" style="color:white;">Digilagel Services Pvt.Ltd.</a> All rights reserved. 
        </div>
        
        <div class="col-xl-4 col-lg-4 col-md-4 col-sm-4 mb-2 text-truncate text-right">
            Copyright © 2019-2020
        </div>
</div>


import { Component, ViewChild, OnInit, HostListener } from '@angular/core';
import { FormBuilder, FormArray, FormGroup, Validators, FormControl } from "@angular/forms";
import { ProjectService } from './project.service';
import {RootObject} from "./Projects.model";
@Component({
  selector: 'app-project',
  templateUrl: './project.component.html',
  styleUrls: ['./project.component.css']
})
export class ProjectComponent implements OnInit {
  public innerWidth: any;
  form: FormGroup;
  ConvertLabel:string="Covert To SpringBoot"
fieldsByModel = [
   { name: "PLANT", id: "3333" },
];
    listOperationTypes = [
    { name: "EQUAL_TO", value: "EQUAL_TO" },
    { name: "NOT_EQUAL_TO", value: "NOT_EQUAL_TO" },
    { name: "LESS_THAN", value: "LESS_THAN" },
    { name: "LESS_THAN_OR_EQUAL_TO", value: "LESS_THAN_OR_EQUAL_TO" },
    { name: "GREATER_THAN", value: "GREATER_THAN" },
    { name: "GREATER_THAN_OR_EQUAL_TO", value: "GREATER_THAN_OR_EQUAL_TO" }
  ];

  listColumnTypes = [
    { name: "String", value: "String" ,selected:'selected' },
    { name: "Boolean", value: "Boolean",selected:'' },
    { name: "Number", value: "Number" ,selected:'' },
    { name: "Integer", value: "Integer" ,selected:'' },
    { name: "Float", value: "Float" ,selected:'' },
    { name: "Double", value: "Double" ,selected:'' },
    { name: "Long", value: "Long" ,selected:'' },    
    { name: "Date", value: "Date" ,selected:'' },
    { name: "Array", value: "Array",selected:''  },
    { name: "Enum", value: "Enum",selected:''  },
    { name: "List", value: "List",selected:''  },
    { name: "Any", value: "Any",selected:''  },
    { name: "Object", value: "Object",selected:''  },    
    { name: "Reference", value: "Reference",selected:''  },
    { name: "File Upload", value: "File Upload",selected:''  },
    { name: "Youtube Link", value: "Youtube Link",selected:''  },
    { name: "Video Link", value: "Video Link",selected:''  },
    { name: "Audio Link", value: "Audio Link",selected:''  },
    { name: "PDF Link", value: "PDF Link",selected:''  },
    { name: "File Link", value: "File Link",selected:''  },
    { name: "Formula", value: "Formula",selected:''  },
    { name: "Rules", value: "Rules",selected:''  },
    
  ];

  listFormControlTypes = [
    { name: "Text", value: "text" ,selected:'selected'},
    { name: "Number", value: "number",selected:'' },
    { name: "Checkbox", value: "checkbox",selected:'' },
    { name: "Radio", value: "radio",selected:'' },
    { name: "Select", value: "select",selected:'' },
    { name: "File", value: "file",selected:'' },
    { name: "Hidden", value: "hidden",selected:'' }
    
  ];

    constructor(private fb: FormBuilder, private projectService: ProjectService) {

  }

  ngOnInit() {
    //let startNumber = 0;
    this.form = this.fb.group({
      userName:'vilas',
     
      'projectsGroups': this.fb.array([
        this.createProjects()
      ])
    }); 
    this.form.valueChanges.subscribe(data => {
      this.updateFormData()
     });
  }
  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.innerWidth = window.innerWidth;
  }
  updateFormData() {
    const control = <FormArray>this.form.controls['projectsGroups'];  
      control.value.map((condition,index)=>{
        condition.order = index; 
      })
  }

  createProjects() {
    return this.fb.group({ 
      //  ---------------------forms fields on x level ------------------------
     // 'X': ['X', [Validators.required, Validators.pattern('[0-9]{3}')]],
      // ---------------------------------------------------------------------
      'order':['Project'+new Date().getTime()],
      'projectName':[''],
      'projectDescription':[''],
      'startDate':[''],
      'endDate':[''],
      'emailId':[''],
      'privacyPolicy':[''],
      'termsOfUse':[''],
      'gitURL':[''],
      'liveLink':[''],
      'stage':[''],
      'status':[''],
      
      'modulesGroups': this.fb.array([
        this.createModules()
      ]),
      'tools': this.fb.array([
        //this.createModules()
      ]),
      'technologies': this.fb.array([
        //this.createModules()
      ]),
      'task': this.fb.array([
       // this.createModules()
      ]),
      'team': this.fb.array([
        //this.createModules()
      ]),
      'activities': this.fb.array([
        //this.createModules()
      ])
    });
  }

  duplicateConditionFunc(ix,item) {

  let projectsGroups = this.form.get("projectsGroups") as FormArray; 
   projectsGroups.insert(ix + 1, this.duplicateCondition(ix,item));
  } 
 
    duplicateCondition(ix,item) {
 
    console.log (typeof item.value.modulesGroups[ix].componentsGroups);
      
    return this.fb.group({ 

      'order':[new Date().getTime()],
      'modulesGroups': this.fb.array([
        this.fb.group({
          'moduleName': item.value.modulesGroups[ix].moduleName,
          'moduleDescription': item.value.modulesGroups[ix].moduleDescription,
          
          'componentsGroups': [item.value.modulesGroups[ix].componentsGroups]
        })
      ])
    });
  }


  createModules() {
    return this.fb.group({ 
      //  ---------------------forms fields on y level  ------------------------
      'order':['Module'+new Date().getTime()],
      'moduleName': [''],
      'moduleDescription': [''],     
      // ---------------------------------------------------------------------
      'componentsGroups': this.fb.array([
        this.createComponents()
      ])
    })
  }

  createComponents() {
    return this.fb.group({
      'order':['Component'+new Date().getTime()],
      'componentName': [''],
      'componentDescription': [''], 
      'tablesGroups': this.fb.array([this.createTable()
      ])            
    })
  }


  createTable() {
    return this.fb.group({
      'order':[new Date().getTime()],
      'columnName': [''],
      'columnLabel': [''],
      'columnType': ['String'], 
      'columnSize': ['10'],
      'defaultValue':[''],
      'isPrimary':[''],
      'formControl':['text'],
      'columnTypeReferenceSelected':['String'],
      'class':[''],
      'style':[''],
      'gridSize':[''],

    })
  }

  addProject(ix) {
    console.log (ix); 
    const control = <FormArray>this.form.controls['projectsGroups'];
    control.push(this.createProjects()); 
     this.updateFormData();
  } 

  removeProject(ix) {
    let projectsGroups = this.form.get("projectsGroups") as FormArray;
    projectsGroups.removeAt(ix);
     this.updateFormData()
  } 

  addModule(ix) {
    const control = (<FormArray>this.form.controls['projectsGroups']).at(ix).get('modulesGroups') as FormArray;
    control.push(this.createModules());
     this.updateFormData()
  }

  removeModule(ix, iy) {
    const control = (<FormArray>this.form.controls['projectsGroups']).at(ix).get('modulesGroups') as FormArray;
    control.removeAt(iy);
     this.updateFormData() 
  }


  addComponent(ix, iy) {
    console.log(ix,iy);
    const control = ((<FormArray>this.form.controls['projectsGroups']).at(ix).get('modulesGroups') as FormArray).at(iy).get('componentsGroups') as FormArray;
    control.push(this.createComponents());
     this.updateFormData();
  } 

  removeComponent(ix, iy, iz) {  
    const control = ((<FormArray>this.form.controls['projectsGroups']).at(ix).get('modulesGroups') as FormArray).at(iy).get('componentsGroups') as FormArray;
    control.removeAt(iz);
     this.updateFormData()
  }


  addTable(ix, iy,iz) {
    console.log(ix,iy,iz);
    const control = (((<FormArray>this.form.controls['projectsGroups']).at(ix).get('modulesGroups') as FormArray).at(iy).get('componentsGroups') as FormArray).at(iz).get('tablesGroups') as FormArray;
    control.push(this.createTable());
     this.updateFormData();
  } 

  removeTable(ix, iy, iz,izz) {  
    const control = (((<FormArray>this.form.controls['projectsGroups']).at(ix).get('modulesGroups') as FormArray).at(iy).get('componentsGroups') as FormArray).at(iz).get('tablesGroups') as FormArray;
    control.removeAt(izz);
     this.updateFormData()
  }


  submit()
  {
    this.ConvertLabel="Converting ...."
    let ob=new RootObject();
    Object.assign(ob,this.form.value);
    
    this.projectService.create(ob).subscribe(data=>{
      //alert(data);
      this.ConvertLabel="Converted and Sent to your EmailId ....";
    });

  }
  componentNameArrayData=[];
  componentNameClickData="";
  componentNameClick(ent){
    var data=ent.target.value;
    console.log(data);
    //alert(tableForm.get('columnType').value)
    this.componentNameClickData=data;
    
  }
  componentNameChange(ent){
    var data=ent.target.value;
console.log(data);
    //alert(tableForm.get('columnType').value)
    var isAvailable=false;
    for(let arr of this.componentNameArrayData)
    {
      if((arr)==(data+"->1:1") || (arr)==(data+"->1:M")){
        isAvailable=true;
        
      }
      if(arr==this.componentNameClickData){
       delete this.componentNameArrayData[this.componentNameClickData];
        
      }

      if(!isAvailable)
      {
        this.componentNameArrayData.push(data+"->1:1");
        this.componentNameArrayData.push(data+"->1:M");
        isAvailable=true;
      }
    }

    if(!isAvailable)
      {
        this.componentNameArrayData.push(data+"->1:1");
        this.componentNameArrayData.push(data+"->1:M");
        isAvailable=false;
      }

     
   
    
  }

  tableColumnTypeChange(tableForm:FormControl){

    //alert(tableForm.get('columnType').value)
    console.log(this.componentNameArrayData);
    
  }


  public restrictNumeric(e) {
    let input;
    if (e.metaKey || e.ctrlKey) {
      return true;
    }
    if (e.which === 32) {
     return false;
    }
    if (e.which === 0) {
     return true;
    }
    if (e.which < 33) {
      return true;
    }
    input = String.fromCharCode(e.which);
    return !!/[\d\s]/.test(input);
    }

    public restrictCharacter(e) {
      let input;
      if (e.metaKey || e.ctrlKey) {
        return true;
      }
      if (e.which === 32) {
       return false;
      }
      if (e.which === 0) {
       return true;
      }
      if (e.which < 33) {
        return true;
      }
      input = String.fromCharCode(e.which);
      return !!/[a-zA-Z0-9_]/.test(input);
      }

}


export class ProjectsGroup {
    order:              number;
    projectName:        string;
    projectDescription: string;
    modulesGroups:      ModulesGroup[];
}

export class ModulesGroup {
    order:             string;
    moduleName:        string;
    moduleDescription: string;
    componentsGroups:  ComponentsGroup[];
}

export class ComponentsGroup {
    order:                string;
    componentName:        string;
    componentDescription: string;
    tablesGroups:         TablesGroup[];
}

export class TablesGroup {
    order:        number;
    columnName:   string;
    columnLabel:  string;
    columnType:   string;
    columnSize:   string;
    defaultValue: string;
    isPrimary:    string;
    formControl:  string;
    columnTypeReferenceSelected:string;
}

    export class RootObject {
        userName: string;
        projectsGroups: ProjectsGroup[];
    }




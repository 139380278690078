import { DigilegalUtil, HighlightPage } from './case.model';
import { TodoItemFlatNode } from './digilegalHome.component';


export class AngularSearchPojo {
free_word :string;
act_name :string;
type_no :string;
act_name2 :string;
type_no2 :string;
pub_name :string;
pub_year :string;
volume :string;
page_no :string;
judge_name :string;
appealant :string;
respondent :string;
h_name :string;
judgement_date :string;
judgement_start_date :string;
judgement_end_date :string;
status :string;
exactmatchsearch :string;
exactWordRadioButton :string
nearWordRadioButton :string
allWordRadioButton :string
anyWordRadioButton :string
fullTextRadioButton :string="FullText";
headNoteRadioButton :string;
isFreeWordEntered :string;
caseHighLightList:HighlightPage ;
finalqueryExactWord:string;
finalqueryAllWord:string;
finalqueryAnyWord:string;
finalqueryNearWord:string;
finalquery:string;
selectedCaseId:string;
htmlEditorFullText:string;
htmlEditorHeadNoteFullText:string;
htmlEditorEquivalentCitationFulltext:string;
htmlEditorcasesReferredFullText:string;
htmlEditorActsReferredFullText:string;
htmlEditorCaseNoteFullText:string;
htmlEditorOverRuleFullText:string;
htmlEditorCitationInFullText:string;
finalJudgeMent:string;
offset:number=0;
count:number=0;
limit:number=10;
currentIndexPostion:number=0;
action:string="ResultSearch";
searchType:string;
is_case_extra:string;
truePrintData:string;
lastPageNo:number;
todoItemFlatNode:TodoItemFlatNode[];
finalKeywords:string;
}
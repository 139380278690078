

import {Injectable} from "@angular/core";
import {HttpClient, HttpErrorResponse, HttpParams} from "@angular/common/http";
import {BehaviorSubject, Observable, Subject, throwError} from "rxjs";
import {catchError, map} from "rxjs/operators";
import { BookMark, HighlightPage, PasswordClass } from "./case.model";
import { environment } from 'src/environments/environment';
import { AngularSearchPojo } from './AngularSearchPojo.model';
import { TodoItemFlatNode } from './digilegalHome.component';


@Injectable()
export class CaseService {

  SharingData = new Subject<any>(); 
  SharingCaseInfoData = new BehaviorSubject<any>(new AngularSearchPojo());
  ViewedCaseInfoData : BehaviorSubject<Array<any>> = new BehaviorSubject([]);
  SharingSelectedJudgementData = new Subject<any>(); 
  menuChange = new Subject<any>(); 
  todoItemFlatNodeArray=new Subject<TodoItemFlatNode[]>();
  isHeaderRequired = new Subject<any>();
   // private readonly API_URL = 'assets/data/case.json';
   //private readonly API_URL = 'http://5.189.132.169:4215/case/';
   // #BCI Chnages Start#
   //private readonly API_URL = 'http://localhost:4216/case/';   
   // #BCI Chnages END#
   public readonly API_URL_ACT_SEARCH =  environment.apiUrlActSearch;
   public readonly API_URL =  environment.apiUrl;
   public readonly TRUEPRINT_API_URL =  environment.apiUrl+'getTruePrintPDF';
   public readonly DOWNLOAD_TRUEPRINT_API_URL = environment.apiUrl+'downloadTruePrintPDF';
   public readonly DOWNLOAD_JUSGEMENT_API_URL = environment.apiUrl+'getFulltextJudgementPDF';
   private readonly DATAENTRY_API_URL =  environment.apiDataEntryUrl;

    constructor(private http:HttpClient) {

    }


    updateShredJudgement(data)
    {
      this.SharingData.next(data);
      this.SharingCaseInfoData.next(data);  
    }

    updateResultShredJudgement(ob)
    {
      this.create(ob).subscribe(data => {
      this.SharingData.next(data);
      this.SharingCaseInfoData.next(data); 
      }); 
    }


    findAllCases(): Observable<HighlightPage> {
        return this.http.get<HighlightPage>(this.API_URL+"repoAll/"+"murder");
    }

    
    searchDataByJudgement(data:string): Observable<HighlightPage> {
        return this.http.get<HighlightPage>(this.API_URL+"repoAll/"+data);
    }


    createByDefault(): Observable<any> {
      let ob = new HighlightPage();
      return this.http.post(this.API_URL+"searchData", ob);
    }
    create(data:AngularSearchPojo): Observable<any> {
            return this.http.post(this.API_URL+"searchData", data);
      }

      saveBookMark(bookMarkArray:Array<BookMark>): Observable<any> {
        return this.http.post(this.API_URL+"saveBookMark", bookMarkArray);
      }

      updateBookMark(bookMarkArray:Array<string>): Observable<any> {
        return this.http.post(this.API_URL+"updateBookMark", bookMarkArray);
      }

      deleteBookMark(bookMarkArray:Array<string>): Observable<any> {
        return this.http.post(this.API_URL+"deleteBookMark", bookMarkArray);
      }

      

      searchDataBySearchType(data): Observable<any> {
        return this.http.post(this.API_URL+"searchDataBySearchType", data);
      }

      searchAppealant(data:string): Observable<any> {
          console.log("searchAppealant="+data);
        return this.http.get<any>(this.API_URL+"searchAppealant/"+data);
    }

    searchAppealantByRespondent(data:string): Observable<any> {
      console.log("searchAppealantByRespondent="+data);
    return this.http.get<any>(this.API_URL+"searchAppealantByRespondent/"+data);
}

    searchRespondent(data:string): Observable<any> {
        console.log("searchRespondent="+data);
      return this.http.get<any>(this.API_URL+"searchRespondent/"+data);
  }

  searchRespondentByAppealant(data:string): Observable<any> {
    console.log("searchRespondentByAppealant="+data);
  return this.http.get<any>(this.API_URL+"searchRespondentByAppealant/"+data);
}

  searchActName(data:string): Observable<any> {
    console.log("searchActName="+data);
  return this.http.get<any>(this.API_URL+"searchActName/"+data);
}

searchTypeNo(data:string): Observable<any> {
  console.log("searchTypeNo="+data);
return this.http.get<any>(this.API_URL+"searchTypeNo/"+data);
}

searchTypeNoByActName(data:string): Observable<any> {
  console.log("searchTypeNoByActName="+data);
return this.http.get<any>(this.API_URL+"searchTypeNoByActName/"+data);
}

searchJudges(data:string): Observable<any> {
  console.log("searchJudges="+data);
return this.http.get<any>(this.API_URL+"searchJudges/"+data);
}

/* searchPubName(data:string): Observable<any> {
  //console.log("searchPubName="+data);
return this.http.get<any>(this.API_URL+"searchPubName/"+data);
} */

searchPage(data:string): Observable<any> {
  console.log("searchPage="+data);
return this.http.get<any>(this.API_URL+"searchPage/"+data);
}

searchVolume(data:string): Observable<any> {
  console.log("searchVolume="+data);
return this.http.get<any>(this.API_URL+"searchVolume/"+data);
}

searchYear(data:string): Observable<any> {
  console.log("searchYear="+data);
return this.http.get<any>(this.API_URL+"searchYear/"+data);
}


findFullTextWithHighlight(data): Observable<any> {
  return this.http.post(this.API_URL+"findFullTextWithHighlight", data);
}

findAllHistory(): Observable<any> {
  return this.http.get<any>(this.API_URL+"historyList");
}
 
findHistoryResultById(id:number): Observable<any> {
  return this.http.get<any>(this.API_URL+"findHistoryResultById/"+id);
}
 

findAllBookMark(): Observable<any> {
  return this.http.get<any>(this.API_URL+"bookmMarkList");
}

bookmMarkNames(): Observable<any> {
  return this.http.get<any>(this.API_URL+"bookmMarkNames");
}


findAllByBookMarkByName(data:any): Observable<any> {
  return this.http.get<any>(this.API_URL+"findAllByBookMarkByName/"+data);
}

isGenerateKey(): Observable<PasswordClass> {
  return this.http.get<PasswordClass>(this.API_URL+"isGenerateKey").pipe(
    catchError(this.handleError)
    );
}

generateKey(name:string): Observable<PasswordClass> {
  return this.http.get<PasswordClass>(this.API_URL+"generateKey/"+name);
}

generateKeyForVerification(name:any,serialNumber:any): Observable<any> {
  return this.http.get<any>(this.API_URL+"verifyGenerateKey/"+name+"/"+serialNumber);
}

verifyGenerateKey(name:any,serialNumber:any): Observable<any> {
  return this.http.get<any>(this.API_URL+"verifyGenerateKey/"+name+"/"+serialNumber);
}

saveRegistrationDetails(data): Observable<any> {
  return this.http.post(this.API_URL+"saveRegistrationDetails", data);
}

lastJudgementUpdated(): Observable<any> 
{
  return this.http.get<any>(this.API_URL+"lastJudgementUpdated");
}

handleError(error: HttpErrorResponse){
  console.log("lalalalalalalala");
  return throwError(error);
  }

  saveActSearch(data): Observable<any> {
    return this.http.post(this.API_URL_ACT_SEARCH+"createActSearch", data);
  }

 

  createCaseInfo(data:AngularSearchPojo): Observable<any> {
    return this.http.post(this.DATAENTRY_API_URL, data);
}


getCaseInfoById(caseInfoId): Observable<any> {
  return this.http.get(this.DATAENTRY_API_URL+"/"+caseInfoId);
}



}
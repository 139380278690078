import { Page404Component } from './authentication/page404/page404.component';
import { AuthLayoutComponent } from './layout/app-layout/auth-layout/auth-layout.component';
import { MainLayoutComponent } from './layout/app-layout/main-layout/main-layout.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from './core/guard/auth.guard';
import { SignupComponent } from './digilegal/search/signup.component';
import { ProjectComponent } from './project/project.component';
import { SCkeyComponent } from './digilegal/search/sckey.component';
import { BCIkeyComponent } from './digilegal/search/bcikey.component';

const routes: Routes = [
  {
    path: '',
    component: MainLayoutComponent,
    
    
    children: [
      { 
        path: '', redirectTo: '/digilegal-search', pathMatch: 'full' 
      },
      {
        path: 'mainLayout',
        component: MainLayoutComponent
      }
    ,
      {
        path: 'dashboard',
        loadChildren: () =>
          import('./dashboard/dashboard.module').then((m) => m.DashboardModule),
      },
      
      {
        path: 'digilegal-search',
        loadChildren: () =>
          import('./digilegal/search/digilegal-search.module').then((m) => m.DigilegalSearchModule),
      },
      
      {
        path: 'digilegal-search/:id',
        loadChildren: () =>
          import('./digilegal/search/digilegal-search.module').then((m) => m.DigilegalSearchModule),
      },
      
    ],
  },
  { path: 'project', component: ProjectComponent },
  { path: 'signup', component: SignupComponent }, 
  { path: '**', redirectTo: '' },
 
];
@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule],
})
export class AppRoutingModule {}

<div>
  <!-- Left Sidebar -->
  <aside id="leftsidebar" class="sidebar h4HeadingTop" (mouseenter)="mouseHover($event)" (mouseleave)="mouseOut($event)">
    <!-- Menu -->
    <div class="menu">
      <ul class="list"
        [ngStyle]="{'position' : 'relative', 'max-height' : listMaxHeight+'px', 'max-width' : listMaxWidth+'px'}"
        [perfectScrollbar]>
        <li class="sidebar-user-panel">
          <div class="user-panel">
            <div class=" image">
              <img src="assets/images/logo.png" class="img-circle user-img-circle" alt="User Image" />
            </div>
          </div>
          <div class="profile-usertitle">
            <!--// #BCI Chnages Start#-->
            <div class="sidebar-userpic-name" style="color:white;">BCR Books</div>
            <!--// #BCI Chnages END#-->
            <div class="profile-usertitle-job " style="color:white;">Updated Upto {{lastJudgementUpdated | date: 'dd-MM-yyyy'}} </div>
          </div>
          <div class="sidebar-userpic-btn" style="display:none;">
            <!-- <a mat-icon-button disabled routerLink="extra-pages/profile" matTooltip="Profile"
              [matTooltipPosition]="'above'">
              <i class="material-icons-two-tone">account_circle</i>
            </a> -->
            <a mat-icon-button disabled routerLink="digilegal-search/history" matTooltip="History" [matTooltipPosition]="'above'">
              <i class="material-icons-two-tone">history</i>
            </a>
            <a mat-icon-button disabled routerLink="digilegal-search/bookMark" matTooltip="BookMark" [matTooltipPosition]="'above'">
              <i class="material-icons-two-tone">bookmark</i>
            </a>
           
            <a mat-icon-button disabled routerLink="authentication/locked" matTooltip="Lock"
              [matTooltipPosition]="'above'">
              <i class="material-icons-two-tone">lock</i>
            </a>
          </div>
        </li>
        <!-- Top Most level menu -->

        <li _ngcontent-mly-c126="" class="ng-star-inserted active" (click)="isSerchOrResultPageFunction('search')" style="display:none;">
          <!----><a style="cursor: pointer;" _ngcontent-mly-c126="" class="menu-top ng-star-inserted"><i _ngcontent-mly-c126=""
              class="material-icons-two-tone">image_search</i><span _ngcontent-mly-c126="" class="hide-menu">Search</span></a>
          <!---->
          <!---->
        </li>
        <li _ngcontent-mly-c126="" class="ng-star-inserted active" (click)="isSerchOrResultPageFunction('result')" style="display:none;">
          <!----><a style="cursor: pointer;" _ngcontent-mly-c126="" class="menu-top ng-star-inserted"><i _ngcontent-mly-c126=""
              class="material-icons-two-tone">grading</i><span _ngcontent-mly-c126="" class="hide-menu">Result </span></a>
          <!---->
          <!---->
        </li>
        <li _ngcontent-mly-c126="" class="ng-star-inserted active" style="display:none;">
          <!----><a _ngcontent-mly-c126="" class="menu-top ng-star-inserted" routerLink="digilegal-search/history"><i _ngcontent-mly-c126=""
              class="material-icons-two-tone">history</i><span _ngcontent-mly-c126="" class="hide-menu">History </span></a>
          <!---->
          <!---->
        </li>
        <li _ngcontent-mly-c126="" class="ng-star-inserted active" style="display:none;"> 
          <!----><a _ngcontent-mly-c126="" class="menu-top ng-star-inserted" routerLink="digilegal-search/bookMark"><i _ngcontent-mly-c126=""
              class="material-icons-two-tone">bookmark</i><span _ngcontent-mly-c126="" class="hide-menu">BookMark </span></a>
          <!---->
          <!---->
        </li>
        <!--// #BCI Chnages Start#-->
        <li _ngcontent-mly-c126="" class="ng-star-inserted active" style="display:none;">
          <!----><a _ngcontent-mly-c126="" class="menu-top ng-star-inserted" routerLink="digilegal-search/overruled"><i _ngcontent-mly-c126=""
              class="material-icons-two-tone">layers</i><span _ngcontent-mly-c126="" class="hide-menu">Overruled </span></a>
          <!---->
          <!---->
        </li>
        <li _ngcontent-mly-c126="" class="ng-star-inserted active" style="display:none;">
          <!----><a _ngcontent-mly-c126="" class="menu-top ng-star-inserted" routerLink="digilegal-search/profile"><i _ngcontent-mly-c126=""
              class="material-icons-two-tone">layers</i><span _ngcontent-mly-c126="" class="hide-menu">Profile </span></a>
          <!---->
          <!---->
        </li>
        <!--// #BCI Chnages END#-->
        <!--// #BCI Chnages Start#-->
        <li _ngcontent-mly-c126="" class="ng-star-inserted active" style="display:none;">
          <!----><a _ngcontent-mly-c126="" class="menu-top ng-star-inserted" routerLink="digilegal-search/actSearch"><i _ngcontent-mly-c126=""
              class="material-icons-two-tone">layers</i><span _ngcontent-mly-c126="" class="hide-menu">ActSearch </span></a>
          <!---->
          <!---->
        </li>
        <li _ngcontent-mly-c126="" class="ng-star-inserted active" style="display:none;"> 
          <!----><a _ngcontent-mly-c126="" class="menu-top ng-star-inserted" routerLink="digilegal-search/sckey"><i _ngcontent-mly-c126=""
              class="material-icons-two-tone">layers</i><span _ngcontent-mly-c126="" class="hide-menu">SC Key </span></a>
          <!---->
          <!---->
        </li>
        <li _ngcontent-mly-c126="" class="ng-star-inserted active" style="display:none;">
          <!----><a _ngcontent-mly-c126="" class="menu-top ng-star-inserted" routerLink="digilegal-search/bcikey"><i _ngcontent-mly-c126=""
              class="material-icons-two-tone">layers</i><span _ngcontent-mly-c126="" class="hide-menu">BCI Key </span></a>
          <!---->
          <!---->
        </li>
        <li _ngcontent-mly-c126="" class="ng-star-inserted active" style="display:none;">
          <!----><a _ngcontent-mly-c126="" class="menu-top ng-star-inserted" routerLink="digilegal-search/dataEntry"><i _ngcontent-mly-c126=""
              class="material-icons-two-tone">layers</i><span _ngcontent-mly-c126="" class="hide-menu">Data Entry </span></a>
          <!---->
          <!---->
        </li>
        <li _ngcontent-mly-c126="" class="ng-star-inserted active">
          <!----><a _ngcontent-mly-c126="" class="menu-top ng-star-inserted" routerLink="digilegal-search/bcrBooks"><i _ngcontent-mly-c126=""
              class="material-icons-two-tone">layers</i><span _ngcontent-mly-c126="" class="hide-menu">BCR Book </span></a>
          <!---->
          <!---->
        </li>
        <!--// #BCI Chnages END#-->
      </ul>
    </div>
    <!-- #Menu -->
  </aside>
  <!-- #END# Left Sidebar -->
</div>

<app-header></app-header>
<app-sidebar></app-sidebar>
<app-right-sidebar></app-right-sidebar>
<section class="content">
  <div class="container-fluid">
    
    <div class="row clearfix">
      <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
        <div class="card">
          
          <div class="body">
<form [formGroup]="form">
  <div class="row">
    <!-- X -->
    <div formArrayName="projectsGroups" class="col-lg-12">
      <div class="col-lg-12" *ngFor="let projectForm of form['controls'].projectsGroups['controls']; let ix=index">
        <div class="col-lg-12">
          <div class="card">
            <div class="card-body projectsGroups" formGroupName="{{ix}}">
              <h4 class="form-header text-uppercase" style="display:none;">
                <i class="fa fa-envelope-o"></i>
                Project {{ix+1}}<input type="button" style="display: none;" class="btn btn-success float-right"
                  (click)="addModule(ix)" value="Add Modules/Package">
              </h4>
              <input type="hidden" class="form-control" formControlName="order" id="order{{ix}}">
              <mat-tab-group>
                <mat-tab label="PROJECT">
                  <div class="form-group row">
                    <label for="projectName{{ix}}" class="col-sm-2 col-form-label">Project Name</label>
                    <div class="col-sm-4">
                      <input type="text" class="form-control" formControlName="projectName" id="projectName{{ix}}">
                    </div>
                    <label for="emailId{{ix}}" class="col-sm-2 col-form-label">EmailId</label>
                    <div class="col-sm-4">
                      <input type="email" class="form-control" formControlName="emailId"  id="emailId{{ix}}"> </div>
                  </div>
                  <div class="form-group row">
                    
                    <label for="projectDescription{{ix}}" class="col-sm-2 col-form-label">Description</label>
                    <div class="col-sm-10">
                      <textarea rows="5" class="form-control" formControlName="projectDescription"  id="projectDescription{{ix}}"></textarea>
                     </div>
                  </div>
                  <div class="form-group row">
                    <label for="startDate{{ix}}" class="col-sm-2 col-form-label">Start Date</label>
                    <div class="col-sm-4">
                      <input type="date" class="form-control" formControlName="startDate" id="startDate{{ix}}">
                    </div>
                    <label for="endDate{{ix}}" class="col-sm-2 col-form-label">End Date</label>
                    <div class="col-sm-4">
                      <input type="date" class="form-control" formControlName="endDate"  id="endDate{{ix}}"> </div>
                  </div>
                  
                  <div class="form-group row">
                    <label for="gitURL{{ix}}" class="col-sm-2 col-form-label">gitURL</label>
                    <div class="col-sm-4">
                      <input type="url" class="form-control" formControlName="gitURL" id="gitURL{{ix}}">
                    </div>
                    <label for="liveLink{{ix}}" class="col-sm-2 col-form-label">liveLink</label>
                    <div class="col-sm-4">
                      <input type="url" class="form-control" formControlName="liveLink"  id="liveLink{{ix}}"> 
                    </div>
                  </div>

                  
                  <div class="form-group row">
                    <label for="privacyPolicy{{ix}}" class="col-sm-2 col-form-label">PRIVACY POLICY</label>
                    <div class="col-sm-10">
                    <textarea rows="5" class="form-control" formControlName="privacyPolicy"  id="privacyPolicy{{ix}}" placeholder="Privacy Policy"></textarea>
                    </div>
                  </div>

                  
                  <div class="form-group row">
                    <label for="termsOfUse{{ix}}" class="col-sm-2 col-form-label">TERMS OF USE</label>
                    <div class="col-sm-10">
                    <textarea rows="5" class="form-control" formControlName="termsOfUse"  id="termsOfUse{{ix}}" placeholder="Terms and Conditions"></textarea>
                    </div>
                  </div>

                 

                </mat-tab>
                <mat-tab label="SCREENS">
                  <!-- Y -->
                  <div formArrayName="modulesGroups" class="col-lg-12">
                    <div *ngFor="let moduleForm of projectForm['controls'].modulesGroups['controls']; let iy=index" class="row">
                      <div formGroupName="{{iy}}" class="col-lg-12">
                        <!--<h4 class="form-header text-uppercase">
                      <i class="fa fa-envelope-o"></i>
                      Module {{iy+1}}

                      <input type="button" class="btn btn-success float-right" (click)="addComponent(ix,iy)"
                        value="Add Components/Form" id="input-112">
                    </h4>

                    <div class="form-group row">
                      <label for="moduleName{{ix}}{{iy}}" class="col-sm-2 col-form-label">module Name</label>
                      <div class="col-sm-3">
                        <input type="text" class="form-control" formControlName="moduleName" id="moduleName{{ix}}{{iy}}" />

                      </div>
                      <label for="moduleDescription{{ix}}{{iy}}" class="col-sm-2 col-form-label">module Description</label>
                      <div class="col-sm-3">
                        <input type="text" class="form-control" formControlName="moduleDescription" id="moduleDescription{{ix}}{{iy}}" />
                      </div>



                      <div class="col-sm-2">
                        <button class="btn btn-danger waves-effect waves-light" (click)="removeModule(ix,iy)"><i
                            class="fa fa fa-trash-o"></i></button>


                      </div>

                    </div>

                  -->
                        <!-- Z -->
                        <div formArrayName="componentsGroups">
                          <div
                            *ngFor="let componentForm of moduleForm['controls'].componentsGroups['controls']; let iz=index">
                            <div formGroupName="{{iz}}" class="componentsGroups">
                              <h4 class="form-header text-uppercase">
                                <i class="fa fa-envelope-o"></i>
                                Component {{iz+1}}
                                <input type="button" class="btn btn-success float-right" (click)="addComponent(ix,iy)"
                                  value="Add Components/Form" id="input-{{ix}}{{iy}}{{iz}}">
                              </h4>
                              <div class="form-group row">
                                <label for="componentName{{ix}}{{iy}}{{iz}}" class="col-sm-2 col-form-label">Component
                                  Name</label>
                                <div class="col-sm-3">
                                  <input type="text" class="form-control" formControlName="componentName"
                                    id="componentName{{ix}}{{iy}}{{iz}}" (change)='componentNameChange($event)'
                                    (click)='componentNameClick($event)' /> </div>
                                <label for="componentDescription{{ix}}{{iy}}{{iz}}"
                                  class="col-sm-2 col-form-label">Component Description</label>
                                <div class="col-sm-3">
                                  <input type="text" class="form-control" formControlName="componentDescription"
                                    id="componentDescription{{ix}}{{iy}}{{iz}}" /> </div>
                                <div class="col-sm-2">
                                  <button class="btn btn-danger waves-effect waves-light"
                                    (click)="removeComponent(ix,iy,iz)"><i class="fa fa fa-trash-o"></i></button>
                                </div>
                              </div>
                              <!--CreateTable-->
                              <div formArrayName="tablesGroups">
                                <h4 class="form-header text-uppercase">
                                  <i class="fa fa-envelope-o"></i>
                                  Table <input type="button" class="btn btn-success float-right"
                                    (click)="addTable(ix,iy,iz)" value="add Table/Field" id="input-112">
                                </h4>
                                <div class="form-group row">
                                  <label for="input-101" class="col-sm-1 col-form-label">Pri</label>
                                  <label for="input-101" class="col-sm-2 col-form-label">column Name</label>
                                  <label for="input-101" class="col-sm-2 col-form-label">COLUMN Label</label>
                                  <label for="input-101" class="col-sm-2 col-form-label">column Type</label>
                                  <label for="input-101" class="col-sm-1 col-form-label">Size</label>
                                  <!--<label for="input-101" class="col-sm-1 col-form-label">default Value</label>-->
                                  <label for="input-101" class="col-sm-2 col-form-label" style="display: none;">Form
                                    Control</label>
                                  <label for="input-101" class="col-sm-2 col-form-label">Action</label>
                                </div>
                                <div
                                  *ngFor="let tableForm of componentForm['controls'].tablesGroups['controls']; let izz=index">
                                  <div formGroupName="{{izz}}" class="tablesGroups">
                                    <div class="form-group row">
                                      <div class="col-sm-1">
                                        <div class="icheck-material-primary">
                                          <input type="checkbox" formControlName="isPrimary"
                                            id="isPrimary{{ix}}{{iy}}{{iz}}{{izz}}" />
                                          <label for="isPrimary{{ix}}{{iy}}{{iz}}{{izz}}"></label>
                                        </div>
                                      </div>
                                      <div class="col-sm-2">
                                        <input type="text" class="form-control" formControlName="columnName"
                                          (keypress)="restrictCharacter($event)"
                                          id="columnName{{ix}}{{iy}}{{iz}}{{izz}}" placeholder="FIRST_NAME,isActive"
                                          title="FIRST_NAME,isActive" /> </div>
                                      <div class="col-sm-2">
                                        <input type="text" class="form-control" formControlName="columnLabel"
                                          id="columnLabel{{ix}}{{iy}}{{iz}}{{izz}}" placeholder="FIRST NAME,Is Active"
                                          placeholder="FIRST NAME,Is Active" /> </div>
                                      <div class="col-sm-2">
                                        <select (change)='tableColumnTypeChange(tableForm)' class="form-control"
                                          formControlName="columnType" id="columnType{{ix}}{{iy}}{{iz}}{{izz}}"
                                          *ngIf="(tableForm && tableForm.get('isPrimary').value=='' || tableForm && tableForm.get('isPrimary').value==false)">
                                          <option *ngFor="let columnTypes of listColumnTypes"
                                            [ngValue]="columnTypes.value"> {{columnTypes.name}} </option>
                                        </select>
                                        <select (change)='tableColumnTypeChange(tableForm)' class="form-control"
                                          formControlName="columnType" id="columnType{{ix}}{{iy}}{{iz}}{{izz}}"
                                          *ngIf="(tableForm && tableForm.get('isPrimary').value==true)">
                                          <option *ngFor="let columnTypes of listColumnPrimaryTypes"
                                            [ngValue]="columnTypes.value"> {{columnTypes.name}} </option>
                                        </select>
                                      </div>
                                      <div class="col-sm-1">
                                        <input type="number" class="form-control" formControlName="columnSize"
                                          id="columnSize{{ix}}{{iy}}{{iz}}{{izz}}"
                                          (keypress)="restrictNumeric($event)" /> </div>
                                      <div class="col-sm-2" style="display: none;"> </div>
                                      <div class="col-sm-2">
                                        <button class="btn btn-danger waves-effect waves-light"
                                          (click)="removeTable(ix,iy,iz,izz)"><i class="fa fa fa-trash-o"></i></button>
                                        &nbsp;
                                        <button class="btn btn-danger waves-effect waves-light"><i
                                            class="icon-settings"></i> </button>
                                      </div>
                                    </div>
                                    <!--For Type Reference-->
                                    <div style="background-color: #a7d7ee;padding-bottom: 10px;"
                                      *ngIf="tableForm && tableForm.get('columnType') && tableForm.get('columnType').value=='Reference'">
                                      <div class="form-group row">
                                        <div class="col-sm-2"> </div>
                                        <div class="col-sm-2"></div>
                                        <div class="col-sm-2"> Select Reference </div>
                                      </div>
                                      <div class="form-group row">
                                        <div class="col-sm-2"></div>
                                        <div class="col-sm-2"></div>
                                        <div class="col-sm-4">
                                          <select
                                            *ngIf="tableForm && tableForm.get('columnType') && tableForm.get('columnType').value=='Reference'"
                                            class="form-control" formControlName="columnTypeReferenceSelected"
                                            id="columnTypeReferenceSelected{{ix}}{{iy}}{{iz}}{{izz}}">
                                            <option *ngFor="let columnTypes of componentNameArrayData"
                                              [ngValue]="columnTypes"> {{columnTypes}} </option>
                                          </select>
                                        </div>
                                      </div>
                                    </div>
                                    <!--For Type Reference-->
                                    <!--For Type Reference-->
                                    <div style="background-color: #a7d7ee;padding-bottom: 10px;"
                                      *ngIf="tableForm && tableForm.get('columnType') && (tableForm.get('columnType').value=='List' || tableForm.get('columnType').value=='Array' || tableForm.get('columnType').value=='Enum')">
                                      <div class="form-group row">
                                        <div class="col-sm-2"></div>
                                        <div class="col-sm-2"> Control Type </div>
                                        <div class="col-sm-4"> List Value(Comma Seperated) </div>
                                      </div>
                                      <div class="form-group row">
                                        <div class="col-sm-2"></div>
                                        <div class="col-sm-2">
                                          <select class="form-control" formControlName="formControl">
                                            <option *ngFor="let formControlTypes of listFormControlTypes"
                                              [ngValue]="formControlTypes.value"
                                              [attr.selected]="formControlTypes.selected!=''?true:null">
                                              {{formControlTypes.name}} </option>
                                          </select>
                                        </div>
                                        <div class="col-sm-4">
                                          <input type="text" class="form-control" formControlName="defaultValue"
                                            placeholder="Mumbai,Pune" id="defaultValue{{ix}}{{iy}}{{iz}}{{izz}}" />
                                        </div>
                                      </div>
                                    </div>
                                    <!--For Type Reference-->
                                  </div>
                                </div>
                              </div>
                              <!--Close CreateTable-->
                            </div>
                          </div>
                        </div>
                        <!-- Z End -->
                      </div>
                    </div>
                  </div>
                  <!-- Y End-->
                </mat-tab>
                <mat-tab label="SETTINGS"> SETTINGS </mat-tab>                
                <mat-tab label="TOOLS"> TOOLS </mat-tab>
                <mat-tab label="TECHNOLOGIES"> TECHNOLOGIES </mat-tab>
                <mat-tab label="EPIC"> EPIC </mat-tab>
                <mat-tab label="TEAM"> TEAM </mat-tab>
                <mat-tab label="ACTIVITIES"> ACTIVITIES </mat-tab>
                <mat-tab label="TERMS OF USE"> TERMS OF USE </mat-tab>
                <mat-tab label="PRIVACY POLICY"> PRIVACY POLICY </mat-tab>
                <mat-tab label="CONTACT US"> CONTACT US </mat-tab>
                <mat-tab label="CAREERS"> CAREERS </mat-tab>
                <mat-tab label="PARTNERS"> PARTNERS </mat-tab>
                <mat-tab label="INVESTORS"> INVESTORS </mat-tab>
                <mat-tab label="REVIEWS"> REVIEWS </mat-tab>
                <mat-tab label="SUGGESTIONS"> SUGGESTIONS </mat-tab>
                <mat-tab label="MANUAL TEST CASES"> MANUAL TEST CASES </mat-tab>
                <mat-tab label="AUTOMATION TEST CASES"> MANUAL TEST CASES </mat-tab>
                <mat-tab label="BUILD"> BUILD </mat-tab>
                <mat-tab label="POM DEPENDENCIES"> POM DEPENDENCIES </mat-tab>
              </mat-tab-group>
            </div>
            <div class="form-footer">
              <button class="btn btn-success" (click)="submit()">{{ConvertLabel}}</button>
              <input type="button" class="btn btn-success" (click)="addProject(ix + 1)" value="Add Project"
                style="display:none;">
              <input type="button" class="btn btn-danger" (click)="removeProject(ix)" value="Remove Project"
                style="display:none;">
              <!--<input type="button" class="btn btn-success" (click)="duplicateConditionFunc(ix, condition)" value="Duplicate Project">-->
            </div>
          </div>
        </div>
      </div>
      <hr>
    </div>
    <!-- X End -->
  </div>
  <!--End Row-->
</form>


</div>
</div>
</div>
</div>
</div>
</section>
<div class="limiter">
  <div class="container-login100">
    <div class="wrap-login100">
      <form class="login100-form validate-form" [formGroup]="loginForm" (ngSubmit)="onSubmit()">
        <span class="login100-form-title p-b-30">
          <!-- #BCI Chnages Start# -->
          Registration For BCI
          <!--// #BCI Chnages END#-->
        </span>
        <span>
          Contact at 9321947751 / 022-66343514
        </span>
        <mat-form-field class="example-full-width" appearance="outline">
          <mat-label>Username</mat-label>
          <input matInput formControlName="username" required readonly>
          <mat-icon matSuffix>face</mat-icon>                    
          <mat-error *ngIf="loginForm.get('username').hasError('required')">
            Username is required
          </mat-error>
        </mat-form-field>
        
        <mat-form-field class="example-full-width" appearance="outline">
          <mat-label>Licence Name</mat-label>
          <input matInput formControlName="licenceName" required >
          <mat-icon matSuffix>face</mat-icon>
          <mat-error *ngIf="loginForm.get('licenceName').hasError('required')">
            Licence Name is required
          </mat-error>
        </mat-form-field>
        <mat-form-field class="example-full-width" appearance="outline">
          <mat-label>Email</mat-label>
          <input matInput formControlName="email" required>
          <mat-icon matSuffix>mail</mat-icon>
          <mat-error *ngIf="loginForm.get('email').hasError('required') || loginForm.get('email').touched">
            Please enter a valid email address
          </mat-error>
        </mat-form-field>
        <mat-form-field class="example-full-width" appearance="outline">
          <mat-label>Phone Number</mat-label>
          <input matInput formControlName="phone" required>
          <mat-icon matSuffix>phone</mat-icon>
          <mat-error *ngIf="loginForm.get('phone').hasError('required') || loginForm.get('phone').touched">
            Please enter a valid Phone Number
          </mat-error>
        </mat-form-field>
        <mat-form-field class="example-full-width" appearance="outline">
          <mat-label>City</mat-label>
          <input matInput formControlName="city" required>
          <mat-icon matSuffix>location-city</mat-icon>
          <mat-error *ngIf="loginForm.get('city').hasError('required') || loginForm.get('city').touched">
            Please enter a valid City
          </mat-error>
        </mat-form-field>

        <mat-form-field class="example-full-width" appearance="outline" style="display:none;">
          <mat-label>Reg. Code</mat-label>
          <input matInput formControlName="serialNumber" readonly>
          <mat-icon matSuffix>vpn_key</mat-icon>
          
        </mat-form-field>
        
        <mat-form-field class="example-full-width" appearance="outline" style="display:none;">
          <mat-label>Reg. Code</mat-label>
          <input matInput formControlName="regCode" readonly>
          <mat-icon matSuffix>vpn_key</mat-icon>
          <mat-error *ngIf="loginForm.get('regCode').hasError('required') || loginForm.get('regCode').touched">
            Please enter a RegCode City
          </mat-error>
        </mat-form-field>
        <mat-form-field class="example-full-width" appearance="outline">
          <mat-label>Reg. Key</mat-label>
          <input matInput formControlName="regKey"  (blur)="regKeyChange()">
          <mat-icon matSuffix>vpn_key</mat-icon>
          <mat-error *ngIf="loginForm.get('regKey').hasError('required') || loginForm.get('regKey').touched">
            Please enter a RegKey City
          </mat-error>
        </mat-form-field>
        <!-- <mat-form-field class="example-full-width" appearance="outline">
          <mat-label>Password</mat-label>
          <input matInput formControlName="password" [type]="hide ? 'password' : 'text'" required>
          <mat-icon matSuffix (click)="hide = !hide">
            {{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
          <mat-error *ngIf="loginForm.get('password').hasError('required')">
            Password is required
          </mat-error>
        </mat-form-field>
        <mat-form-field class="example-full-width" appearance="outline">
          <mat-label>Confirm Password</mat-label>
          <input matInput formControlName="cpassword" [type]="chide ? 'password' : 'text'" required>
          <mat-icon matSuffix (click)="chide = !chide">
            {{chide ? 'visibility_off' : 'visibility'}}</mat-icon>
          <mat-error *ngIf="loginForm.get('cpassword').hasError('required')">
            Confirm Password is required
          </mat-error>
        </mat-form-field> -->
        
        
        <div class="container-login100-form-btn" *ngIf="submittedErrorMsg=='ValidRegKey'">
          <button mat-flat-button color="primary" class="login100-form-btn" type="submit">
            Register
          </button>
        </div>
        <div class="flex-sb-m w-full p-t-15 p-b-20" *ngIf="submittedErrorMsg=='InvalidRegKey'">
          <mat-error>
            Invalid Reg Key
          </mat-error>
        </div>
        
        <!-- <div class="text-center p-t-30 p-b-20">
          <span class="txt2">
            or sign up using
          </span>
        </div>
        <div class="login100-form-social flex-c-m">
          <a href="#" class="login100-form-social-item flex-c-m bg1 m-r-5">
            <i class="fab fa-facebook-f"></i>
          </a>
          <a href="#" class="login100-form-social-item flex-c-m bg2 m-r-5">
            <i class="fab fa-twitter"></i>
          </a>
        </div> -->
      </form>
      <div class="login100-more" style="background-image: url('assets/images/products/sc.jpg');">
      </div>
    </div>
  </div>
</div>
